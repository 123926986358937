<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Funder Companies</span>
        <v-spacer></v-spacer>
        <v-btn class="mt-3" color="primary" @click="openCreateModal">
          <v-icon>mdi-plus</v-icon>
          <span class="ml-2">Create</span>
        </v-btn>
      </v-card-title>

      <div v-if="loading">
        <v-card>
          <v-card-text>
            <v-container>
              <v-progress-circular class="mx-auto flex" indeterminate color="primary"></v-progress-circular>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
      <div v-else>
        <DataTableHeader show-search-box :headers="headers" @update:search="searchText = $event" />
        <v-data-table data-has-actions="true" :headers="$filterHeaders(headers)" :items="funderList" :search="searchText">
          <template v-slot:item.outcomesText="{ item }">
            <v-chip v-for="outcome in item.outcomesText" :key="outcome" small>
              {{ outcome }}
            </v-chip>
          </template>
          <template v-slot:item.challengesText="{ item }">
            <v-chip v-for="challenge in item.challengesText" :key="challenge" small>
              {{ challenge }}
            </v-chip>
          </template>
          <template v-slot:item.techAreasText="{ item }">
            <v-chip v-for="techArea in item.techAreasText" :key="techArea" small>
              {{ techArea }}
            </v-chip>
          </template>
          <template v-slot:item.industriesText="{ item }">
            <v-chip v-for="industry in item.industriesText" :key="industry" small>
              {{ industry }}
            </v-chip>
          </template>
          <template v-slot:item.fundingTypesText="{ item }">
            <v-chip v-for="fundingType in item.fundingTypesText" :key="fundingType" small>
              {{ fundingType }}
            </v-chip>
          </template>
          <template v-slot:item.funding.minimumRevenuePerMonth="{ item }">£ {{ item.funding.minimumRevenuePerMonth }}</template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon color="gray" @click.stop="editItem(item)">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.actions2="{ item }">
            <v-btn icon color="gray" class="mx-auto" @click.stop="deleteItem(item)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CreateCompanyModal from '@/components/company/CreateCompanyModal';
import DataTableHeader from '@/components/ui/DataTableHeader';
import DataTableHeaderMixin from '@/mixins/DataTableHeader';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from '@/helpers/constants';

export default {
  name: 'FunderCompanyList',
  components: { DataTableHeader },
  data: () => ({
    headers: [
      { text: 'Edit', value: 'actions', sortable: false, width: '30px', align: 'center' },
      { text: 'Company Name', value: 'name' },
      { text: 'City', value: 'location', hideable: true, show: true },
      { text: 'Country', value: 'country.name', hideable: true, show: false },
      { text: 'Region', value: 'region', hideable: true, show: false },
      { text: 'Type', value: 'companyType.name', hideable: true, show: true },
      { text: 'Inn. Areas', value: 'techAreasText', hideable: true, show: false },
      { text: 'Description', value: 'description', hideable: true, show: false },
      { text: 'SCInsights', value: 'SCInsights', hideable: true, show: false },
      { text: 'Outcomes', value: 'outcomesText', hideable: true, show: false },
      { text: 'Challenges', value: 'challengesText', hideable: true, show: false },
      { text: 'Industries', value: 'industriesText', hideable: true, show: false },
      { text: 'Website', value: 'website', hideable: true, show: false },
      { text: 'Geography of Focus', value: 'geographyOfFocus', hideable: true, show: false },
      { text: 'Raising Now', value: 'raisingNow', hideable: true, show: false },
      { text: 'Updated Date', value: 'updatedAt', hideable: true, show: false },
      { text: 'Actions', value: 'actions2', sortable: false, width: '30px', align: 'center' },
    ],
    searchText: '',
    loading: false,
  }),
  computed: {
    ...mapState(['funders', 'companyTypes']),
    ...mapGetters(['getFunderCompanyTypeId']),
    funderList() {
      return this.funders.map((c) => {
        return {
          ...c,
          outcomesText: c.outcomes.map((o) => o.name),
          challengesText: c.challenges.map((o) => o.name),
          techAreasText: c.techAreas.map((o) => o.name),
          industriesText: c.industries.map((o) => o.name),
          fundingTypesText: c.fundingTypes.map((o) => o.name),
          fundingStagesText: c.fundingStages.map((o) => o.name),
          lastActive: dayjs(c?.funding?.lastActive).format(DEFAULT_DATE_FORMAT),
          updatedAt: dayjs(c?.updated_at).format(DEFAULT_DATE_FORMAT),
        };
      });
    },
  },
  created() {
    this.fetchFundersData();
  },
  methods: {
    ...mapActions(['fetchFunders', 'deleteCompany']),
    async fetchFundersData() {
      this.loading = true;
      await this.fetchFunders();
      this.loading = false;
    },
    openCreateModal() {
      this.$showModal(CreateCompanyModal, { defaultCompanyTypeId: this.getFunderCompanyTypeId });
    },
    editItem(item) {
      this.$showModal(CreateCompanyModal, { id: item.id });
    },
    deleteItem(item) {
      const modal = this.$showConfirmModal(null, {
        confirm: () => {
          this.deleteCompany(item.id);
          modal.close();
        },
      });
    },
  },
  mixins: [DataTableHeaderMixin],
};
</script>
